
const localEnvironment = process.env.REACT_APP_FRONTEND_LOCAL_ENV === 'true';
const stagingRoute = process.env.REACT_APP_FRONTEND_STAGING_ENV === 'true';
const productionEnvironment = process.env.REACT_APP_FRONTEND_PROD_ENV === 'true';


export const parentApi = () => {
    if (localEnvironment) return process.env.REACT_APP_FRONTEND_STAGING_ENV_URL
    else if (stagingRoute) return process.env.REACT_APP_FRONTEND_STAGING_ENV_URL
    else if (productionEnvironment) return process.env.REACT_APP_FRONTEND_PROD_ENV_URL
    else return '';
}

export const baseUrl = parentApi();
export const imageBaseUrl = process.env.REACT_APP_FRONTEND_PROD_ENV_URL
export const LoginWithGoogleBaseUrl = parentApi();



